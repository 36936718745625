import React, { useEffect, useRef } from "react";
import { useDrag } from 'react-dnd';
import CardFlip from "./CardFlip";

const DraggableCard = ({ image, number, imageDefault, onDragEnd, cardWidth }) => {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: 'CARD',
      item: { image: image.url, number },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        if (monitor.didDrop())
          onDragEnd(number); // Уведомляем об удалении карты
      },
    }),
    [image]
  );

  const cardStyle = {
    flex: "0 0 10%",
    padding: "10px",
    boxSizing: "border-box",
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab'
  };

  if (isDragging || image == null)
    return <div ref={dragRef} />

  return (
    <div ref={dragRef} style={cardStyle}>
      <CardFlip image={image} imageDefault={imageDefault} number={number} cardWidth={cardWidth} />
    </div>
  );
};

const CardGrid = ({ images, imageDefault, setImages, cardWidth }) => {
  const gridStyle = {
    display: "flex",
    overflow: "auto"
  };

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      // Отменить стандартное поведение прокрутки
      event.preventDefault();

      // Прокрутка горизонтально в зависимости от направления колеса мыши
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += event.deltaY;
      }
    };

    // Добавляем обработчик с { passive: false }
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleWheel, { passive: false });
    }

    // Очистка при размонтировании компонента
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const handleDragStart = (cardIndex) => {
    // Убираем карту из списка (скрываем)
    setImages((prevImages) =>
      prevImages.map((image, index) =>
        index === cardIndex ? { ...image, visible: false } : image // Устанавливаем `null` для скрытой карты
      )
    );
  };

  return (
    <div
      style={gridStyle}
      ref={scrollContainerRef}
    >
      {images.map((image, index) => {
        if (!image.visible)
          return <React.Fragment key={index} />

        return <DraggableCard
          key={index}
          image={image}
          number={index + 1}
          imageDefault={imageDefault}
          onDragEnd={() => handleDragStart(index)} // Обрабатываем начало перетаскивания
          cardWidth={cardWidth}
        />
      })}
    </div>
  );
};

export default CardGrid;