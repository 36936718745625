import React, { useEffect, useRef, useState } from "react";

const CardFlip = ({ image, imageDefault, number, cardWidth }) => {
  const [isFlipped, setIsFlipped] = useState(image.flipped);
  const cardRef = useRef(null);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    setIsFlipped(image.flipped);
  }, [image]);

  const circleStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#333',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '36px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none"
  };

  const cardHeight = cardWidth * 1.5;
  const cardMargin = Math.min(cardWidth / 10, 20);

  const cardStyle = {
    perspective: "1000px",
    width: cardWidth,
    height: cardHeight,
    minWidth: "100px",
    minHeight: "150px",
    maxWidth: "200px",
    maxHeight: "300px",
    margin: cardMargin,
    position: "relative",
    transition: "transform 0.6s ease-out, width 0.6s ease-out, height 0.6s ease-out",
  };

  return (
    <div style={cardStyle} ref={cardRef}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backfaceVisibility: "hidden",
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(180deg)" : "rotateY(0deg)",
          transition: "transform 0.6s ease-out",
          overflow: "hidden",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <img
          onClick={handleFlip}
          src={image.url}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          alt="Card front"
        />
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          backfaceVisibility: "hidden",
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateY(0deg)" : "rotateY(-180deg)",
          transition: "transform 0.6s ease-out",
          overflow: "hidden",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#f0f0f0"
        }}
      >
        <div
          onClick={handleFlip}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative"
          }}
        >
          <div style={circleStyle}>
            {number}
          </div>
          <img
            src={imageDefault}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Card back"
          />
        </div>
      </div>
    </div>
  );
};

export default CardFlip;
