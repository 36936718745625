import update from 'immutability-helper';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { IconContext } from "react-icons";
import { FaSistrix, FaTimes } from "react-icons/fa";


const cardAreaStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
}

const getDropAreaOffset = (dropAreaRef) => {
  const rect = dropAreaRef.current.getBoundingClientRect();
  return {
    x: rect.left,
    y: rect.top,
  };
};

const getDropAreaSize = (dropAreaRef) => {
  const rect = dropAreaRef.current.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
  };
};

const DraggableCardInArea = ({ index, card, cardWidth, onDelete, onPreview }) => {
  const [{ isDragging }, dragRef] = useDrag(
    () => ({
      type: 'CARD_MOVE',
      item: { id: index, x: card.x, y: card.y },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [index, card],
  );

  if (isDragging)
    return <div ref={dragRef} />

  const cardHeight = cardWidth * 1.5; // Задайте высоту карты

  const cardStyle = {
    position: 'absolute',
    width: cardWidth,
    height: cardHeight,
    minWidth: "100px",
    minHeight: "150px",
    maxWidth: "200px",
    maxHeight: "300px",
    cursor: 'grab',
    borderRadius: '10px',
    backgroundColor: "#f0f0f0",
    overflow: 'hidden'
  }

  return (
    <div
      ref={dragRef}
      style={{
        ...cardStyle,
        top: card.y,
        left: card.x,
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <IconContext.Provider value={{ size: Math.min(cardHeight * 0.2, 24), className: "controlIcon" }}>
        <div className="areaCardControls">
          <FaTimes onClick={onDelete} />
          <FaSistrix onClick={onPreview} />
        </div>
      </IconContext.Provider>
      <img src={card.image} alt={`Card ${card.number}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </div>
  );
};

const CardArea = ({ cardWidth, images, setImages }) => {
  const [cards, setCards] = useState([]);
  const [preview, setPreview] = useState(null);

  const cardHeight = cardWidth * 1.5; // Задайте высоту карты

  const dropAreaRef = useRef(null);

  const [, dropRef] = useDrop(() => ({
    accept: 'CARD',
    drop: (item, monitor) => {
      const offset = monitor.getSourceClientOffset(); // Глобальные координаты
      const dropAreaOffset = getDropAreaOffset(dropAreaRef); // Локальные координаты DropArea
      const dropAreaSize = getDropAreaSize(dropAreaRef);

      if (!offset || !dropAreaOffset) return;

      const newCard = {
        id: Date.now(),
        image: item.image,
        number: item.number,
        x: Math.max(0, Math.min(offset.x - dropAreaOffset.x, dropAreaSize.width - cardWidth)), // Привязка к DropArea
        y: Math.max(0, Math.min(offset.y - dropAreaOffset.y, dropAreaSize.height - cardHeight)),
      };
      setCards((prev) => [...prev, newCard]);
    }
  }));

  const [, moveRef] = useDrop(() => ({
    accept: 'CARD_MOVE',
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      if (!delta) return;

      const dropAreaSize = getDropAreaSize(dropAreaRef);

      const left = Math.max(0, Math.min(Math.round(item.x + delta.x), dropAreaSize.width - cardWidth))
      const top = Math.max(0, Math.min(Math.round(item.y + delta.y), dropAreaSize.height - cardHeight))

      updateCardPosition(item.id, left, top);
    }
  }));

  const updateCardPosition = useCallback(
    (id, left, top) => {
      setCards((prevCards) =>
        update(prevCards, {
          [id]: {
            $merge: { x: left, y: top },
          },
        }),
      )
    },
    [setCards],
  )

  const handleDelete = (areaIndex, cardIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [[areaIndex, 1]],
      }),
    )

    // Возвращаем карту в список (показываем)
    setImages((prevImages) =>
      prevImages.map((image, index) =>
        index === cardIndex ? { ...image, visible: true, flipped: false } : image // Устанавливаем `null` для скрытой карты
      )
    );
  }

  const handlePreview = (image_url) => {
    const addFullFolder = (url) => {
      const lastSlashIndex = url.lastIndexOf('/'); // Найти последний слэш
      if (lastSlashIndex === -1) return url; // Если слэша нет, вернуть исходный URL

      const folderPath = url.slice(0, lastSlashIndex); // Путь до имени файла
      const fileName = url.slice(lastSlashIndex + 1); // Имя файла с расширением
      return `${folderPath}/full/${fileName}`; // Добавить /full/ перед именем файла
    };

    const fullImageUrl = image_url ? addFullFolder(image_url) : null;
    setPreview(fullImageUrl);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') { // Проверяем, нажата ли клавиша Esc
        handlePreview(null); // Вызываем handlePreview с нужным параметром
      }
    };

    window.addEventListener('keydown', handleKeyDown); // Добавляем обработчик
    return () => {
      window.removeEventListener('keydown', handleKeyDown); // Удаляем обработчик при размонтировании
    };
  });

  return (
    <React.Fragment>
      {preview &&
        <div className="fullCard">
          <div className="fullCardControls">
            <IconContext.Provider value={{ size: Math.min(cardHeight * 0.2, 24), className: "controlIcon" }}>
              <div className="areaCardControls">
                <FaTimes onClick={() => handlePreview(null)} />
              </div>
            </IconContext.Provider>
            <img src={preview} alt="card_preview" />
          </div>
        </div>
      }
      <div
        ref={(node) => {
          dropRef(node);
          moveRef(node);
          dropAreaRef.current = node;
        }}
        style={cardAreaStyle}
      >
        {cards.map((card, key) => (
          <DraggableCardInArea
            key={card.id}
            index={key}
            card={card}
            cardWidth={cardWidth}
            onDelete={() => handleDelete(key, card.number - 1)}
            onPreview={() => handlePreview(card.image)}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default CardArea