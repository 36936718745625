import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaTools } from "react-icons/fa";
import CardArea from './CardArea';
import CardGrid from './CardGrid';
import decks from './cards.json';
import Modal from './Modal';

import './App.css';

function App() {
  const defCard = 'default.jpg';
  const folder = 'cards';

  const [selectedCategory, setSelectedCategory] = useState('universal');
  const categories = Object.keys(decks);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);
  };

  const imageUrls = useMemo(() => Object.keys(decks)
    .filter((deckName) => selectedCategory === deckName)
    .flatMap((deckName) => decks[deckName].map(card => `${folder}/${deckName}/${card}`))
    , [selectedCategory]);

  ////////////////////////////

  // State to hold the current images and the set of used image indices
  const [images, setImages] = useState([]);

  // Function to get random images from the array, without repeating images
  const getRandomImages = useCallback(() => {
    const randomImages = [];
    let usedIndices = [];

    while (randomImages.length < imageUrls.length) {
      const randomIndex = Math.floor(Math.random() * imageUrls.length);
      if (!usedIndices.includes(randomIndex)) {
        randomImages.push({
          url: imageUrls[randomIndex],
          visible: true,
          flipped: true
        });
        usedIndices.push(randomIndex);
      }
    }

    // If all images have been used, reset the usedIndices array
    if (usedIndices.length === imageUrls.length)
      usedIndices = [];

    setImages(randomImages);
  }, [imageUrls])

  useEffect(() => {
    getRandomImages();
  }, [getRandomImages])

  // Function to handle the button click
  function handleRefreshClick() {
    getRandomImages();
    toggleModal();
  }

  // State to hold the modal visibility
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function to toggle the modal visibility
  function toggleModal() {
    setIsModalVisible(!isModalVisible);
  }

  function calcCardWidth() {
    const cardCount = 8;
    return Math.ceil((window.innerWidth - (cardCount * 60)) * 0.8 / cardCount);
  }

  const [cardWidth, setCardWidth] = useState(calcCardWidth());

  useEffect(() => {
    const handleResize = () => {
      setCardWidth(calcCardWidth());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      {isModalVisible &&
        <Modal
          defCard={defCard}
          folder={folder}
          handleCategoryChange={handleCategoryChange}
          handleRefreshClick={handleRefreshClick}
          selectedCategory={selectedCategory}
          categories={categories}
          handleClose={toggleModal}
        />
      }
      <DndProvider backend={HTML5Backend}>
        <div className="App">
          <div className="controls">
            <div className="settingBtn" onClick={toggleModal}><FaTools size={32} /></div>
          </div>
          <div className="cards">
            <CardGrid
              images={images}
              setImages={setImages}
              imageDefault={`${folder}/${defCard}`}
              cardWidth={cardWidth}
            />
          </div>
          <div className="cardsArea" style={{ marginBottom: '20px' }}>
            <CardArea
              images={images}
              setImages={setImages}
              cardWidth={cardWidth}
            />
          </div>
        </div>
      </DndProvider>
    </React.Fragment>
  );
}

export default App;
